<template>
  <div id="divFaqWrapper" class="faq-wrapper">
    <div v-html="content"></div>
  </div>
</template>
<script>
import StaticContent from '@/services/Api/staticContents';
export default {
  name: 'FrequentlyAskedQuestions',
  data() {
    return { content: '' };
  },
  created() {
    StaticContent.getStaticContent('SikcaSorulanSorular').then(res => {
      let {
        Data: { content },
      } = res.data;

      this.content = content;
    });
  },
};
</script>
<style scoped lang="scss">
.faq-wrapper {
  padding: 10px;
  line-height: 24px;
  margin-bottom: 30px;
  .img-thumbnail {
    border: none !important;
  }
}
</style>
